import ReportFee from 'src/models/report-fee';

export const ReportFees: ReportFee[] = [
  {
    id: 113655,
    idreport: 2622,
    idfee: 217233,
    feeDate: '2023-11-16',
    merchant: 'Trenitalia',
    categoryID: 214624,
    feeTotalValue: 12.45,
    km: 0,
    notes: 'Trasferta Torino',
    image: 'https://fees-world.s3.eu-central-1.amazonaws.com/fees/6/1698924967748.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2BVPDEPN7TSL5E7S%2F20231128%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231128T230116Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=230af6f2ff4a3ecfe9f8e27f08e1711f67e7f12a057e7c686099b58eb51490a1',
    warranty: 0,
    warrantyEndDate: null,
    deduction: 0,
    expensesReport: 0,
    status: 1,
    commessaID: null,
    clienteID: null,
    centroCostoID: null,
    exchangeRate: 1,
    currencyId: 1,
    documentNumber: '',
    currencyCode: 'EUR',
    currencySymbol: '€',
    category: 'Biglietti',
    commessa: null,
    cliente: null,
    centri_costo: null,
    username: 'fulvio.lazzari@fees.world',
    tags_list: 'cc personale',
    iduser: 14353,
    feeTotalValueCurrencyEnv: 12.45,
  },
  {
    id: 113656,
    idreport: 2622,
    idfee: 217234,
    feeDate: '2023-11-15',
    merchant: 'Trenitalia',
    categoryID: 214624,
    feeTotalValue: 12.45,
    km: 0,
    notes: 'Trasferta Torino',
    image: 'https://fees-world.s3.eu-central-1.amazonaws.com/fees/6/1699725632851.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2BVPDEPN7TSL5E7S%2F20231128%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231128T230116Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=b07b92cb6d17f71a96f02eef655cf992f34ec58e48cbd0210bd858a55d97e7ad',
    warranty: 0,
    warrantyEndDate: null,
    deduction: 0,
    expensesReport: 0,
    status: 0,
    commessaID: null,
    clienteID: null,
    centroCostoID: null,
    exchangeRate: 1,
    currencyId: 1,
    documentNumber: '',
    currencyCode: 'EUR',
    currencySymbol: '€',
    category: 'Biglietti',
    commessa: null,
    cliente: null,
    centri_costo: null,
    username: 'fulvio.lazzari@fees.world',
    tags_list: 'cc personale',
    iduser: 14353,
    feeTotalValueCurrencyEnv: 12.45,
  },
  {
    id: 113657,
    idreport: 2622,
    idfee: 217235,
    feeDate: '2023-11-14',
    merchant: 'Trenitalia',
    categoryID: 214624,
    feeTotalValue: 12.45,
    km: 0,
    notes: 'Trasferta Torino',
    image: 'https://fees-world.s3.eu-central-1.amazonaws.com/fees/14353/1700163011761.jpg?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIA2BVPDEPN7TSL5E7S%2F20231128%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20231128T230231Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1200&X-Amz-Signature=c59390b6b5a0bd783844f70875a2d07665ef6a1b66a8406ccef00c7c2bfef020',
    warranty: 0,
    warrantyEndDate: null,
    deduction: 0,
    expensesReport: 0,
    status: 2,
    commessaID: null,
    clienteID: null,
    centroCostoID: null,
    exchangeRate: 1,
    currencyId: 1,
    documentNumber: '',
    currencyCode: 'EUR',
    currencySymbol: '€',
    category: 'Biglietti',
    commessa: null,
    cliente: null,
    centri_costo: null,
    username: '',
    tags_list: 'cc personale',
    iduser: 14353,
    feeTotalValueCurrencyEnv: 12.45,
  },
];
